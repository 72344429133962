<i18n src="@/locales/comparative.json" />
<i18n src="@/locales/articles-card-content.json" />

<template>
  <div class="comparative">
    <div class="header-background">
      <ImanHeader
        :title="$t('h1_title')"
      />
    </div>
    <ImanH2>{{ $t('h2_title_1') }}</ImanH2>
    <section class="section">
      <ImanArticle>
        <p>
          {{ $t('article_p1_1') }}
        </p>
        <div v-html="$t('article_p1_2')" />
      </ImanArticle>
    </section>
    <section :class="mainBackgroundClass + ` section`">
      <div class="is-hidden-mobile columns has-background-primary-light-opacity has-text-weight-semibold has-text-white-ter">
        <div class="column is-5">
          <div class="columns is-vcentered">
            <div class="column is-offset-5 is-2">
              <img
                src="@/assets/img/dental-implant/basal-implant/comparative/implant-basal.png"
                width="70"
              >
            </div>
            <div class="column">
              <span v-html="$t('th_1')" />
            </div>
          </div>
        </div>
        <div class="column has-text-white is-5">
          <div class="columns is-vcentered">
            <div class="column is-offset-5 is-2">
              <img
                src="@/assets/img/dental-implant/basal-implant/comparative/implant-dentaire.png"
                width="70"
              >
            </div>
            <div class="column">
              <span v-html="$t('th_2')" />
            </div>
          </div>
        </div>
      </div>
      <div class="is-hidden-tablet columns has-background-primary-light-opacity has-text-weight-semibold has-text-white-ter">
        <div class="columns is-vcentered">
          <div class="column has-text-centered">
            {{ $t('th_3') }}
          </div>
        </div>
      </div>
      <div class="columns has-background-white-ligth">
        <div class="column is-2 has-text-weight-semibold">
          {{ $t('td_1') }}
        </div>
        <div class="column is-5" v-html="$t('content_table_1')" />
        <div class="column is-5" v-html="$t('content_table_2')" />
      </div>
      <div class="columns has-background-white-ligth">
        <div class="column is-2 has-text-weight-semibold">
          {{ $t('td_2') }}
        </div>
        <div class="column is-5" v-html="$t('content_table_3')" />
        <div class="column is-5" v-html="$t('content_table_4')" />
      </div>
      <div class="columns has-background-white-ligth">
        <div class="column is-2 has-text-weight-semibold">
          {{ $t('td_3') }}
        </div>
        <div class="column is-5" v-html="$t('content_table_5')" />
        <div class="column is-5" v-html="$t('content_table_6')" />
      </div>
      <div class="columns has-background-white-ligth">
        <div class="column is-2 has-text-weight-semibold">
          {{ $t('td_4') }}
        </div>
        <div class="column is-5" v-html="$t('content_table_7')" />
        <div class="column is-5" v-html="$t('content_table_8')" />
      </div>
      <div class="columns has-background-white-ligth">
        <div class="column is-2 has-text-weight-semibold">
          {{ $t('td_5') }}
        </div>
        <div class="column is-5" v-html="$t('content_table_9')" />
        <div class="column is-5" v-html="$t('content_table_10')" />
      </div>
      <div class="columns has-background-white-ligth">
        <div class="column is-2 has-text-weight-semibold">
          {{ $t('td_6') }}
        </div>
        <div class="column is-5" v-html="$t('content_table_11')" />
        <div class="column is-5" v-html="$t('content_table_12')" />
      </div>
      <div class="columns has-background-white-ligth">
        <div class="column is-2 has-text-weight-semibold">
          {{ $t('td_7') }}
        </div>
        <div class="column is-5" v-html="$t('content_table_13')" />
        <div class="column is-5" v-html="$t('content_table_14')" />
      </div>
      <div class="columns has-background-white-ligth">
        <div class="column is-2 has-text-weight-semibold">
          {{ $t('td_8') }}
        </div>
        <div class="column is-5" v-html="$t('content_table_15')" />
        <div class="column is-5" v-html="$t('content_table_16')" />
      </div>
    </section>
    <ImanH2>{{ $t('h2_title_2') }}</ImanH2>
    <section class="columns is-centered background-section">
      <div class="column is-8">
        <ImanCollapsableCard
          :title="$t('collapse_title_1')"
        >
          <p>
            {{ $t('collapse_p1') }}
          </p>
        </ImanCollapsableCard>
        <ImanCollapsableCard
          :title="$t('collapse_title_2')"
        >
          <p>
            {{ $t('collapse_p2') }}
          </p>
        </ImanCollapsableCard>
      </div>
    </section>
    <ImanH2>{{ $t('h2_articles') }}</ImanH2>
    <ImanArticleSection :content-list="filteredArticlesCardContent" />
  </div>
</template>

<script>
  import ImanArticleSection from "@/components/ImanArticleSection";
  import {articlesCardContentFilterMixin} from "@/mixins/articles-card-content-filter-mixin";
  import {metaMixin} from "@/mixins/meta-mixin";

  export default {
    name: 'Comparative',
    components: {
      ImanArticleSection
    },
    mixins: [
      articlesCardContentFilterMixin,
      metaMixin
    ],
    data () {
      return {
        articlesCardContentId: [2111, 66, 2245, 88],
        mainBackgroundClass: 'none-background'
      }
    },
    computed: {
      openGraphImgPath () {
        return 'https://anveli.dental' + require('../../../../assets/img/thumbnail/MINIATURE_2.2.4.png')
      }
    },
    created() {
      window.addEventListener('scroll', this.whenScroll)
    },
    destroyed() {
      window.removeEventListener('scroll', this.whenScroll)
    },
    methods: {
      whenScroll() {
        if (document.body.scrollTop > 1 || document.documentElement.scrollTop > 1) {
          this.mainBackgroundClass = 'none-background'
        }
        if (document.body.scrollTop > 1000 || document.documentElement.scrollTop > 1000) {
          this.mainBackgroundClass = 'fixed-background'
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import 'src/assets/style/scss/variables';
  .header-background {
    @media only screen and (min-width: 768px) {
      /* tablet et ordinateurs */
      background-image: url("../../../../assets/img/dental-implant/basal-implant/comparative/H1-difference-implant-dentaire-basal-d.jpg");
    }
    @media only screen and (max-width: 767px) {
      /* smartphone en mode paysage */
      background-image: url("../../../../assets/img/dental-implant/basal-implant/comparative/H1-difference-implant-dentaire-basal-t.jpg");
    }
    @media only screen and (max-width: 767px) and (orientation: portrait) {
       /* smartphone en mode portait */
      background-image: url("../../../../assets/img/dental-implant/basal-implant/comparative/H1-difference-implant-dentaire-basal-m.jpg");
    }
  }

  .has-background-primary-light {
    background-color: rgba(224, 32, 64, 0.70);
  }
  .has-background-white-ligth {
    background-color: rgba(255, 255, 255, 0.7);
    .column {
      &.is-5 {
        text-align: justify;
        padding: 2rem;
        border-left: #dbdbdb solid 2px;
      }
    }
  }
</style>
